<script>
import { RouterView } from 'vue-router'
    export default{
        name: "GuestLayout",
        components: {
            RouterView
        }
    }
</script>
<template>
    <div>
        <RouterView/>
    </div>
</template>
<style>
</style>